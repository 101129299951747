import * as React from 'react';

import { cn } from '../../../@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption';
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  error?: boolean;
  required?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      startAdornment,
      endAdornment,
      variant,
      required,
      ...props
    },
    ref
  ) => {
    const hasAdornment = startAdornment || endAdornment;
    const { onBlur, ...rest } = props;
    const parentClassName =
      'flex h-10 items-center justify-center border border-input flex-nowrap gap-2 hover:border-primary rounded-md bg-background px-3 ring-offset-background transition-all focus-visible:border-primary data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-50';
    return hasAdornment ? (
      <div
        className={cn(parentClassName, className)}
        data-disabled={props.disabled}
      >
        {required && <p className="text-destructive">*</p>}
        {startAdornment && (
          <div className={cn('text-muted-foreground')}>
            {startAdornment}
          </div>
        )}
        <input
          type={type}
          className={cn(
            'flex h-full w-full flex-nowrap rounded-md border-none bg-transparent py-1 shadow-none outline-none',
            'file:bg-transparent file:text-sm file:font-medium',
            'placeholder:text-muted',
            'focus-visible:border-none focus-visible:shadow-none focus-visible:outline-none',
            variant
          )}
          onFocus={(e) => {
            e.target.parentElement.className = cn(
              parentClassName,
              className,
              'border-primary'
            );
          }}
          onBlur={(e) => {
            e.target.parentElement.className = cn(
              parentClassName,
              className,
              'border-input'
            );
            onBlur && onBlur(e);
          }}
          ref={ref}
          {...rest}
        />
        {endAdornment && (
          <div className={cn('text-muted-foreground')}>
            {endAdornment}
          </div>
        )}
      </div>
    ) : (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border-input bg-background px-3 py-1 text-sm transition-colors',
          'file:border-0 file:bg-transparent file:text-sm file:font-medium',
          'placeholder:text-muted',
          'focus-visible:border-primary focus-visible:outline-none',
          'disabled:cursor-not-allowed disabled:opacity-50',
          className,
          variant
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
