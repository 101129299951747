import * as React from 'react';

import { cn } from '@/lib/utils';

export type TextareaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    fitContent?: boolean;
  };

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, fitContent, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'flex min-h-[150px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm placeholder:text-muted',
          'hover:border-primary',
          'focus-visible:border-primary focus-visible:outline-none',
          'disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        onInput={
          fitContent
            ? (e) => {
                // Increase scroll height as user types
                const target = e.target as HTMLTextAreaElement;
                target.style.height = '';
                target.style.height = `${target.scrollHeight}px`;
              }
            : null
        }
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };
